import React, { useEffect, useState } from 'react';
import { Col, Input, Menu, Select, message, Row } from 'antd';
import {ScreenLoadingOverlay} from '@sailgp/sailgp-shared-components';
import { CheckOutlined, CloseOutlined, SearchOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import './Userrequest.scss';
import PendingUser from './PendingUser';
import PastUser from './PastUser';
import RejectedUser from './RejectedUser';
import { fetchGetData, fetchPostData } from '../../../utils/fetchApi';
import { fetchPageData } from '../../../utils/fetchApiData';
import UserRequestFilter from './UserRequestFilter';

export default function UserRequestAdmin() {
	const [showUserRequest, setUserRequest] = useState('existingusers');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
	const { Option } = Select;
	const [pastData, setPastData] = useState({});
	const [pageData, setPageData] = useState({});
	const [pendingData, setPendingData] = useState({});
	const [rejectedData, setRejectedData] = useState({});
	const [loader, setLoader] = useState(false)
	const [selectedUser, setSelectedUser] = useState({});
	const [selectedTab, setSelectedTab] = useState('existingusers');
	const tableDataSource = [];
	const tablePendingDataSource = [];
	const tableRejectedDataSource = [];
	const csvData = [];
	let csvPendingData = [];
	const [, setSortByValue] = useState('');
	const [messageApi, contextHolder] = message.useMessage();
	const [rejectInfo, setRejectInfo] = useState({});
	const [adminRejectInfo, setAdminRejectInfo] = useState({});
	const [approveInfo, setApproveInfo] = useState({});
	const [userRole, setuserRole] = useState({});

	const tableFirstPosition = 'bottom';
	const tableSecondPosition = 'left';
	const pageSize = [10, 25, 50, 100];

	const [, setRoleType] = '';
	let sortByValue = '';
	let userStatus = '';

	const edituserRole = (user, role) => {
		setIsModalOpen(true);
		setSelectedUser(user);
		setuserRole(role);
	};

	const deleteuserRole = user => {
		setIsRejectModalOpen(true);
		setSelectedUser(user);
	};

	let columns = [
		{
			id: 'name',
			key: 'name',
			title: 'Name',
			dataIndex: 'name'
		},
		{
			id: 'email',
			key: 'email',
			title: 'Email',
			dataIndex: 'email'
		},
		{
			id: 'companyName',
			key: 'companyName',
			title: 'Company Name',
			dataIndex: 'companyName',
			ellipsis: true,
		},
		{
			id: 'country',
			key: 'country',
			title: 'Country',
			dataIndex: 'country'
		},
		{
			id: 'status',
			key: 'status',
			title: 'Status',
			dataIndex: 'status'
		},
		{
			id: 'action',
			key: 'action',
			title: 'Action',
			dataIndex: 'action'
		}
	];

	let pendingColumns = [
		{
			id: 'name',
			key: 'name',
			title: 'Name',
			dataIndex: 'name'
		},
		{
			id: 'jobtitle',
			key: 'jobtitle',
			title: 'Job Title',
			dataIndex: 'jobtitle'
		},
		{
			id: 'email',
			key: 'email',
			title: 'Email',
			dataIndex: 'email'
		},
		{
			id: 'country',
			key: 'country',
			title: 'Country',
			dataIndex: 'country'
		},
		{
			id: 'organization',
			key: 'organization',
			title: 'Organization',
			dataIndex: 'organization'
		},
		{
			id: 'phonenumber',
			key: 'phonenumber',
			title: 'Phone Number',
			dataIndex: 'phonenumber'
		},
		{
			id: 'action',
			key: 'action',
			title: 'Action',
			dataIndex: 'action'
		}
	];

	const sortbyOptions = [
		{
			id: 'Newest to Oldest',
			label: 'Newest to Oldest'
		},
		{
			id: 'Oldest to Newest',
			label: 'Oldest to Newest'
		},
		{
			id: 'By Country',
			label: 'By Country'
		},
		{
			id: 'Alphabetical',
			label: 'Alphabetical'
		},
		{
			id: 'Non Verified',
			label: 'Non Verified'
		},
		{
			id: 'Approved users',
			label: 'Approved users'
		}
	];

	const userRolesButton = [
		{
			id: 'journalist',
			label: 'Journalist',
			htmlType: 'button'
		},
		{ id: 'pressOffice', label: 'Press Office', htmlType: 'button' }
	];

	const rejectModalButton = [
		{
			id: 'yes',
			label: 'Yes',
			htmlType: 'button'
		},
		{
			id: 'no',
			label: 'No',
			htmlType: 'button'
		}
	];

	const userRequestsMenu = {
		id: 'userRequestsMenu',
		data: [
			{
				id: 'pendingusers',
				label: 'Pending Users'
			},
			{
				id: 'existingusers',
				label: 'Existing Users',
				class: 'item-selected'
			},
			{
				id: 'rejectedusers',
				label: 'Rejected Users'
			}
		]
	};

	if (pendingData && Object.keys(pendingData).length > 0) {
		pendingData &&
		pendingData.forEach((value, index) => {
			let name = value.name && value.name.givenName + ' ' + value.name.familyName || '';
			let jobtitle = value && value.title;
			let email = value && value.userName;
			let country = value.addresses && value.addresses[0].country;
			let organization =
				value['urn:ietf:params:scim:schemas:idcs:extension:custom:User'] &&
				value['urn:ietf:params:scim:schemas:idcs:extension:custom:User'].Organization;
			let phonenumber = value.phoneNumbers && value.phoneNumbers[0].value;
			tablePendingDataSource.push({
				key: index,
				name: [
					// <img src={profile_1} className="user_profile" alt="user_profile" />,
					name
				],
				jobtitle: [jobtitle],
				email: [email],
				country: [country],
				organization: [organization],
				phonenumber: [phonenumber],
				action: (
					<p className='action-column'>
							<span onClick={e => showModal(e, 'approve', value)}>
								<CheckOutlined />
							</span>
						<span onClick={e => showModal(e, 'reject', value)}>
								<DeleteOutlined />
							</span>
					</p>
				)
			});
			csvPendingData.push({
				key: index + 1,
				name: [
					// <img src={profile_1} className="user_profile" alt="user_profile" />,
					name
				],
				jobtitle: [jobtitle],
				email: [email],
				country: [country],

				companyName: [organization],
				phonenumber: [phonenumber]
			});
		});
	}

	const [value, setValue] = useState('');

	useEffect(() => {
		const getAllPastlimitAPIURL = fetchPageData(`admin/pastUser`);
		getAllPastlimitAPIURL.then(apiURL => {
			const totalData = fetchGetData(apiURL, 'GET', true);
			totalData
				.then(response => response.json())
				.then(data => {
					if (data.status === 1) {
						let totalDataCount = data.data && data.data.totalResults;
						const getAllPastAPIURL = fetchPageData(`admin/pastUser?limit=${totalDataCount}&sortorder=descending`);
						getAllPastAPIURL.then(apiURL => {
							const allPastData = fetchGetData(apiURL, 'GET', true);
							allPastData
								.then(response => response.json())
								.then(data => {
									if (data.status === 1) {
										setPastData(data.data && data.data.Resources);
										setPageData(data.data && data.data);
									}
								})
								.catch(error => {});
						});
					}
				})
				.catch(error => {});
		});

		const getAllPendingAPIURL = fetchPageData(`admin/pendingUser?page=1&limit=&sortorder=descending`);
		getAllPendingAPIURL.then(apiURL => {
			setLoader(false)
			const allPendingData = fetchGetData(apiURL, 'GET', true);
			allPendingData
				.then(response => response.json())
				.then(data => {
					if (data.status === 1) {
						setPendingData(data.data && data.data.Resources);
						setPageData(data.data && data.data);
						setLoader(true)
					}
				})
				.catch(error => {});
		});

		const rejectedUserAPIURL = fetchPageData(`admin/rejectedUser?page=1&limit=&sortorder=descending`);
		rejectedUserAPIURL.then(apiURL => {
			setLoader(false)
			const allRejectedData = fetchGetData(apiURL, 'GET', true);
			allRejectedData
				.then(response => response.json())
				.then(data => {
					if (data.status === 1) {
						setRejectedData(data.data && data.data.Resources);
						setPageData(data.data && data.data);
						setLoader(true);
					}
				})
				.catch(error => {
				});
		});


	}, [rejectInfo, approveInfo, adminRejectInfo]);

	if (rejectedData && Object.keys(rejectedData).length > 0) {
		rejectedData &&
		rejectedData.forEach((value, index) => {
			let name = value.name && value.name.givenName + ' ' + value.name.familyName || '';
			let jobtitle = value && value.title;
			let email = value && value.userName;
			let country = value.addresses && value.addresses[0].country;
			let organization =
				value['urn:ietf:params:scim:schemas:idcs:extension:custom:User'] &&
				value['urn:ietf:params:scim:schemas:idcs:extension:custom:User'].Organization;
			let phonenumber = value.phoneNumbers && value.phoneNumbers[0].value;
			tableRejectedDataSource.push({
				key: index,
				name: [
					name
				],
				jobtitle: [jobtitle],
				email: [email],
				country: [country],
				organization: [organization],
				phonenumber: [phonenumber],
				action: (
					<p className='action-column'>
							<span onClick={e => showModal(e, 'approve', value)}>
								<CheckOutlined />
							</span>
						<span onClick={e => showModal(e, 'reject', value)}>
								<DeleteOutlined />
							</span>
					</p>
				)
			});
		});
	}

	if (pastData && Object.keys(pastData).length > 0) {
		pastData &&
		pastData.forEach((value, index) => {
			let userRole =
				value['urn:ietf:params:scim:schemas:idcs:extension:custom:User'] &&
				value['urn:ietf:params:scim:schemas:idcs:extension:custom:User'].User_Role;
			let email = value.userName;
			let organization =
				value['urn:ietf:params:scim:schemas:idcs:extension:custom:User'] &&
				value['urn:ietf:params:scim:schemas:idcs:extension:custom:User'].Organization;
			let country = value.addresses && value.addresses[0] && value.addresses[0].country;
			const groupsList = value.groups.length > 0 && value.groups.map(({ value }) => value);
			let name = value.name && value.name.givenName + ' ' + value.name.familyName || '';
			tableDataSource.push({
				key: index,
				name: [
					// <img src={profile_1} className="user_profile" alt="user_profile" />,
					name
				],
				companyName: [organization],
				email: [email],
				action:
					groupsList.includes('b52d667042fa4f4f989ddbaa00fb394b') || groupsList.includes('e59c6982b00c483ba4e190cd242e81be') ||
					groupsList.includes('40b721ad4d824c04a2d28531bd05ad63') || groupsList.includes('4717821846fb41498ac81f028d372e17') ? (
						<p className='action-column'>
								<span onClick={() => edituserRole(value, userRole)}>
									<EditOutlined />
								</span>
							<span onClick={() => deleteuserRole(value)}>
									<DeleteOutlined />
								</span>
						</p>
					) : null,

				country: [country],
				status: [groupsList.includes('b52d667042fa4f4f989ddbaa00fb394b') || groupsList.includes('e59c6982b00c483ba4e190cd242e81be') ||
				groupsList.includes('40b721ad4d824c04a2d28531bd05ad63') || groupsList.includes('4717821846fb41498ac81f028d372e17') ?
					<CheckOutlined className='user-accepted' /> : <CloseOutlined />]
			});
			csvData.push({
				key: index + 1,
				name: [
					// <img src={profile_1} className="user_profile" alt="user_profile" />,
					name
				],
				companyName: [organization],
				email: [email],
				country: [country]
			});
		});
	}

	const itemRender = (_, type, originalElement) => {
		if (type === 'prev') {
			return '<<';
		}
		if (type === 'next') {
			return '>>';
		}
		return originalElement;
	};

	const getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => <div></div>,
		filterIcon: filtered => (
			<>
				{dataIndex !== 'status' && dataIndex !== 'action' && value.length === 0 ? <SearchOutlined /> : ''}
				<Input
					onChange={e => {
						const currValue = e.target.value;
						setValue(currValue);
						let filterValue = '';
						if (dataIndex === 'email') {
							filterValue = 'username';
						} else if (dataIndex === 'country') {
							filterValue = 'country';
						} else if (dataIndex === 'name') {
							filterValue = 'name';
						} else {
							filterValue = 'org';
						}
						const getSearchAPIURL = fetchPageData(
							currValue.length !== 0
								? `admin/pastUser?page=1&limit=${pageData && pageData.totalResults}&${filterValue}=${currValue}`
								: `admin/pastUser?limit=${pageData && pageData.totalResults}`
						);

						getSearchAPIURL.then(apiURL => {
							const allSearchData = fetchGetData(apiURL, 'GET', true);
							allSearchData
								.then(response => response.json())
								.then(data => {
									if (data.status === 1) {
										if (data.data && data.data.Resources.length > 0) {
											setPastData(data.data && data.data.Resources);
										} else {
											tableDataSource.push({
												key: [],
												name: [],
												companyName: [],
												email: [],
												country: []
											});
										}
									}
								})
								.catch(error => {});
						});
					}}
				/>
			</>
		)
	});

	const getPendingColumnSearchProps = dataIndex => ({
		filterDropdown: () => <div></div>,
		filterIcon: filtered => <></>
	});

	if (Object.keys(columns).length > 0) {
		columns = columns.map(data => {
			return { ...data, ...getColumnSearchProps(data.id) };
		});
	}

	if (Object.keys(pendingColumns).length > 0) {
		pendingColumns = pendingColumns.map(data => {
			return { ...data, ...getPendingColumnSearchProps(data.id) };
		});
	}

	const handleUserRequest = event => {
		userRequestsMenu.data.forEach(item => {
			setSelectedTab(event.key);
			if (item.id === event.key) {
				setUserRequest(event.key);
			}
		});
	};

	const changeRole = id => {
		const selectedRole = id === 'journalist' ? 'Journalist' : 'PressOffice';
		const changebuttonInfoRoleAPIURL = fetchPageData(`admin/updateuserRole`);
		const redirectURL = location.origin;

		changebuttonInfoRoleAPIURL.then(apiURL => {
			const payLoadBody = {
				role: selectedRole,
				status: '1',
				userid: selectedUser.id,
				email: selectedUser.userName,
				firstName: selectedUser.name.givenName,
				redirectURL
			};

			const changebuttonInfoRole = fetchPostData(apiURL, 'POST', payLoadBody, true);
			changebuttonInfoRole
				.then(response => response.json())
				.then(data => {
					//Getting the response
					if (data.status === 1) {
						setApproveInfo(selectedUser.id);
						messageApi.open({
							type: 'success',
							content: 'User approved Successfully'
						});
						setIsModalOpen(false);
						setRoleType(id);
					} else {
						messageApi.open({
							type: 'error',
							content: 'Unable to approve user'
						});
					}
				})
				.catch(error => {});
		});
	};

	const rejectUser = id => {
		if (id === 'yes') {
			const redirectURL = location.origin;
			const rejectbuttonInfoAPIURL = fetchPageData(`admin/updateuserRole`);
			rejectbuttonInfoAPIURL.then(apiURL => {
				const payLoadBody = {
					status: '0',
					userid: selectedUser.id,
					email: selectedUser.userName,
					firstName: selectedUser.name.givenName,
					redirectURL
				};
				const rejectedbuttonInfo = fetchPostData(apiURL, 'POST', payLoadBody, true);
				rejectedbuttonInfo
					.then(response => response.json())
					.then(data => {
						if (data.status === 1) {
							setIsRejectModalOpen(false);
							setAdminRejectInfo(selectedUser.id);
							messageApi.open({
								type: 'success',
								content: 'User rejected Successfully'
							});
						} else {
							messageApi.open({
								type: 'error',
								content: 'Unable to reject user'
							});
						}
					})
					.catch(error => {});
			});
		} else {
			setIsRejectModalOpen(false);
		}
	};

	const rejectUserByAdmin = id => {
		if (id === 'yes') {
			const rejectbuttonAdminInfoAPIURL = fetchPageData(`admin/userDelete/${selectedUser.id}`);
			rejectbuttonAdminInfoAPIURL.then(apiURL => {
				const rejectedByAdminbuttonInfo = fetchGetData(apiURL, 'DELETE', true);
				rejectedByAdminbuttonInfo
					.then(response => response.json())
					.then(data => {
						if (data.status === 1) {
							setIsRejectModalOpen(false);
							setRejectInfo(selectedUser.id);
							messageApi.open({
								type: 'success',
								content: 'User rejected Successfully'
							});
						} else {
							messageApi.open({
								type: 'error',
								content: 'Unable to reject user'
							});
						}
					})
					.catch(error => {});
			});
		} else {
			setIsRejectModalOpen(false);
		}
	};

	const showModal = (event, buttontype, user) => {
		setSelectedUser(user);
		if (buttontype === 'approve') {
			setIsModalOpen(true);
		} else {
			setIsRejectModalOpen(true);
		}
	};
	const handleCancel = () => {
		setIsModalOpen(false);
		setIsRejectModalOpen(false);
	};

	const handleChange = value => {
		setSortByValue(value);
		if (value === 'Newest to Oldest') {
			sortByValue = 'descending';
		} else if (value === 'Oldest to Newest') {
			sortByValue = 'ascending';
		} else if (value === 'Alphabetical') {
			sortByValue = 'name.givenName';
		} else if (value === 'By Country') {
			sortByValue = 'addresses.country';
		} else if (value === 'Approved users') {
			sortByValue = 'Approved';
		} else {
			userStatus = 'false';
		}

		const getSortByPastUserAPIURL = fetchPageData(
			sortByValue === 'name.givenName' || sortByValue === 'addresses.country' || sortByValue === 'Approved'
				? `admin/pastUser?page=1&limit=${pageData && pageData.totalResults}&sortby=${sortByValue}&sortorder=${'ascending'}`
				: `admin/pastUser?page=1&limit=${pageData && pageData.totalResults}&sortorder=${sortByValue}`
		);
		const getSortBybuttonInfoAPIURL = fetchPageData(
			sortByValue === 'name.givenName' || sortByValue === 'addresses.country'
				? `admin/pendingUser?page=1&limit=${pageData && pageData.totalResults}&sortby=${sortByValue}&sortorder=${'ascending'}`
				: `admin/pendingUser?page=1&limit=${pageData && pageData.totalResults}&sortorder=${sortByValue}&status=${userStatus}`
		);
		const getSortByRejectUserAPIURL = fetchPageData(
			sortByValue === 'name.givenName' || sortByValue === 'addresses.country'
			? `admin/rejectedUser?page=1&limit=${pageData && pageData.totalResults}&sortby=${sortByValue}&sortorder=${'ascending'}`
			: `admin/rejectedUser?page=1&limit=${pageData && pageData.totalResults}&sortorder=${sortByValue}&status=${userStatus}`
		);
		const selectedUserAPIURL = selectedTab === 'existingusers' ? getSortByPastUserAPIURL : selectedTab === 'rejectedusers' ? getSortByRejectUserAPIURL : getSortBybuttonInfoAPIURL;
		setLoader(false)
		selectedUserAPIURL.then(apiURL => {
			const allSortedData = fetchGetData(apiURL, 'GET', true);
			allSortedData
				.then(response => response.json())
				.then(data => {
					if (data.status === 1 && selectedTab === 'existingusers') {
						setPastData(data.data && data.data.Resources);
						setLoader(true)
					}		
					else if (data.status === 1 && selectedTab === 'pendingusers') {
						setPendingData(data.data && data.data.Resources);
						setLoader(true)
					}
					else if (data.status === 1 && selectedTab === 'rejectedusers') {
						setRejectedData(data.data && data.data.Resources);
						setLoader(true)
					}

					
				})
				.catch(error => {});
		});
	};

	return (
		<React.Fragment>
			<section className="user-request-container">
				{pastData && Object.keys(pastData).length > 0 ? (
					<div className="user-requests">
						<Row justify="center">
							{contextHolder}
							<Col xl={11} lg={11} md={15} sm={10} xs={15} className='user-requests-section'>
								<Menu mode='horizontal' theme='dark' defaultSelectedKeys={['existingusers']}>
									{userRequestsMenu.data.map(item => {
										return (
											<Menu.Item  key={item.id} id={item.id} onClick={handleUserRequest} className={item.class}>
												<label>{item.label}</label>
											</Menu.Item>
										);
									})}
								</Menu>
							</Col>
						</Row>
						<UserRequestFilter
							tableDataSource={tableDataSource}
							sortbyOptions={sortbyOptions}
							selectedTab={selectedTab}
							showUserRequest={showUserRequest}
							handleChange={handleChange}
							Option={Option}
							csvData={csvData}
							csvPendingData={csvPendingData}
						/>
						<Row>
							<Col xl={24} lg={24} md={24} sm={24} xs={24} className="table">
								{showUserRequest === 'existingusers' ? (
									//past users
									<PastUser
										tableLayout={'fixed'}
										columns={columns}
										tableFirstPosition={tableFirstPosition}
										pageSize={pageSize}
										tableSecondPosition={tableSecondPosition}
										itemRender={itemRender}
										tableDataSource={tableDataSource}
										userRolesButton={userRolesButton}
										rejectModalButton={rejectModalButton}
										isModalOpen={isModalOpen}
										handleCancel={handleCancel}
										changeRole={changeRole}
										isRejectModalOpen={isRejectModalOpen}
										userRole={userRole}
										rejectUserByAdmin={rejectUserByAdmin}
										loading={loader}
									/>
								) 
								
								: showUserRequest === 'pendingusers' ? 
								
								(
									<PendingUser
										tableLayout={'fixed'}
										columns={pendingColumns}
										tableFirstPosition={tableFirstPosition}
										pageSize={pageSize}
										tableSecondPosition={tableSecondPosition}
										itemRender={itemRender}
										tableDataSource={tablePendingDataSource}
										userRolesButton={userRolesButton}
										rejectModalButton={rejectModalButton}
										isModalOpen={isModalOpen}
										handleCancel={handleCancel}
										changeRole={changeRole}
										isRejectModalOpen={isRejectModalOpen}
										rejectUser={rejectUser}
										loading={loader}
									/>
								)
								: 
								(
									<RejectedUser
										tableLayout={'fixed'}
										columns={pendingColumns}
										tableFirstPosition={tableFirstPosition}
										pageSize={pageSize}
										tableSecondPosition={tableSecondPosition}
										itemRender={itemRender}
										tableDataSource={tableRejectedDataSource}
										userRolesButton={userRolesButton}
										rejectModalButton={rejectModalButton}
										isModalOpen={isModalOpen}
										handleCancel={handleCancel}
										changeRole={changeRole}
										isRejectModalOpen={isRejectModalOpen}
										rejectUser={rejectUser}
										loading={loader}
									/>
								)
							}
							</Col>
						</Row>
					</div>
				) :  <ScreenLoadingOverlay active={true} /> }
			</section>
		</React.Fragment>
	);
}
